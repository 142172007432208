import { Avatar, Badge, Table } from "@mantine/core";
import { LoggedInContainer } from "../Components/LoggedInContainer";
import { IconStar } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getUserCredits } from "../misc/api";

export const PaymentHistory = () => {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    getUserCredits().then((x) => {
      setElements(x);
      console.log(x);
    });
  }, []);

  const rows = elements.map((element) => (
    <tr key={element.order_reference}>
      <td>{element.order_reference}</td>
      <td>{new Date(element.paid).toLocaleString()}</td>
      <td>{element.amount}.00 €</td>
      <td>
        {
          <Badge
            color={
              element.status == "pending"
                ? "orange"
                : element.status == "paid"
                ? "green"
                : "red"
            }
          >
            {element.status}
          </Badge>
        }
      </td>
      <td>
        {element?.valid ? (
          <span>
            Subscription {new Date(element?.valid).toLocaleDateString()}
          </span>
        ) : element?.type == "tt" ? (
          "One flight"
        ) : (
          "Two flights"
        )}
      </td>
    </tr>
  ));

  return (
    <LoggedInContainer>
      <Table>
        <thead>
          <tr>
            <th>Payment ref.</th>
            <th>Datums</th>
            <th>Summa</th>
            <th>Status</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </LoggedInContainer>
  );
};
