import { getToken } from "./tokens";

// const url = "http://localhost:3099/";
const url = "https://spilve-b.smebckup.site/";
const handleResponse = async (response) => {
  if (response.status == 200 || response.status == 201) {
    return await response.json();
  } else if (response.status == 404) {
    return { error: "Not found" };
  } else if (response.status == 400) {
    return { error: "error" };
  } else if (response.status == 401) {
    window.location.href = "/logout";
  }
  console.log(response);
};

export const saveRules = (data) => {
  return fetch(url + `rules/save-rules`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: data }),
  }).then((response) => handleResponse(response));
};

export const getRules = () => {
  return fetch(url + `rules/get-rules`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => handleResponse(response));
};

export const saveQuestions = (data) => {
  return fetch(url + `questions/save-questions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: data }),
  }).then((response) => handleResponse(response));
};

export const getQuestions = () => {
  return fetch(url + `questions/get-questions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => handleResponse(response));
};

export const registerUser = (data) => {
  return fetch(url + `auth/register-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const handleLogin = async (email, password) => {
  console.log("starting");
  const response = await fetch(url + "auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });
  return response;
};

export const checkEmailOrPhone = (data) => {
  return fetch(url + `auth/check-email-phone`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const sentVerifyNumberRequest = (data) => {
  return fetch(url + `auth/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phone: "+" + data }),
  }).then((response) => handleResponse(response));
};

export const verifyReceivedSMSCode = (phone, code) => {
  return fetch(url + `auth/verify-code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phone: "+" + phone, code: code }),
  }).then((response) => handleResponse(response));
};

export const getPaymentLink = (data) => {
  const token = getToken();
  return fetch(url + "payment/get-pay-link", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getFlights = async () => {
  return fetch(url + "get", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const getPaymentStatus = (data) => {
  const token = getToken();
  return fetch(url + "payment/get-pay-status", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getUserCredits = () => {
  const token = getToken();
  return fetch(url + "payment/get-user-credits", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const requestLoggedInFlight = (data) => {
  const token = getToken();
  return fetch(url + "request/request-loggedin-flight", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getUserRequests = () => {
  const token = getToken();
  return fetch(url + "request/get-user-flight-requests", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const getUserRequestCountAdmin = () => {
  const token = getToken();
  return fetch(url + "admin/get-user-pending-count", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const getUserRequestsAdmin = (data) => {
  const token = getToken();
  return fetch(url + "admin/get-user-flight-requests", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const cancelUserFlight = (data) => {
  const token = getToken();
  return fetch(url + "admin/decline-flight", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const confirmUserFlight = (data) => {
  const token = getToken();
  return fetch(url + "admin/confirm-flight", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const adminGetClientPayments = () => {
  const token = getToken();
  return fetch(url + "admin/get-client-payments", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const adminGetUsers = () => {
  const token = getToken();
  return fetch(url + "admin/get-users", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const adminSaveUser = (data) => {
  const token = getToken();
  return fetch(url + "admin/save-user", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const adminAddPayment = (data) => {
  const token = getToken();
  return fetch(url + "admin/add-payment", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const saveAirplane = (data) => {
  const token = getToken();
  return fetch(url + "planes/save", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getUserAirplanes = () => {
  const token = getToken();
  return fetch(url + "planes/get-all", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const getAirplane = () => {
  const token = getToken();
  return fetch(url + "planes/get", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const createAirplane = (data) => {
  const token = getToken();
  return fetch(url + "planes/create", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getClientQuestions = (data) => {
  return fetch(url + "questions/get-client-questions", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const requestSingleFlight = (data) => {
  return fetch(url + "request/request-flight", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response));
};

export const getRulesRead = () => {
  const token = getToken();
  return fetch(url + "user/get-rules-read", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};

export const setRulesRead = () => {
  const token = getToken();
  return fetch(url + "user/set-rules-read", {
    method: "POST",
    headers: { Authorization: `${token}`, "Content-Type": "application/json" },
  }).then((response) => handleResponse(response));
};
