import { DateInput, DateTimePicker, TimeInput } from "@mantine/dates";
import { LoggedInContainer } from "../Components/LoggedInContainer";
import { TooltipIcon } from "../Components/TooltipIcon";
import { Anchor, Button, Checkbox } from "@mantine/core";
import { useCallback, useState } from "react";
import { QuestionModal } from "../Components/QuestionModal";
import { requestLoggedInFlight } from "../misc/api";

export const RequestFlightScreen = () => {
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [request, setRequest] = useState([]);

  const handleInputChange = useCallback(
    (fieldName, newValue) => {
      setRequest((prevState) => ({
        ...prevState,
        [fieldName]: newValue,
      }));
    },
    [request]
  );

  const submitFlightRequest = async () => {
    const response = await requestLoggedInFlight(request);
    if (response.error) {
      window.alert("An error occured! Please try again in a bit");
    } else {
      window.alert("Success! Flight requested, awaiting confirmation!");
      window.location.reload();
    }
  };

  return (
    <LoggedInContainer>
      {/* <QuestionModal
        ruleTestModal={ruleTestModal}
        setRuleTestModal={setRuleTestModal}
      /> */}
      <h3>Request flight</h3>
      <div>
        <TooltipIcon
          value={request.departure_place}
          onChange={(event) =>
            handleInputChange("departure_place", event.target.value)
          }
          label="Origin airport"
          placeholder="RIX"
        />
        <TooltipIcon
          value={request.arrival_place}
          onChange={(event) =>
            handleInputChange("arrival_place", event.target.value)
          }
          label="Arrival airport"
          placeholder="RIX"
        />
        <DateInput
          value={request.dof}
          onChange={(v) => handleInputChange("dof", v)}
          label="DOF"
          placeholder="DDMMYY"
          valueFormat="DDMMYY"
        />
        <TimeInput
          value={request.departure_time}
          onChange={(event) =>
            handleInputChange("departure_time", event.target.value)
          }
          label="Planned time of departure"
        />
        <TimeInput
          value={request.flight_lenght}
          onChange={(event) =>
            handleInputChange("flight_lenght", event.target.value)
          }
          label="Lenght of flight"
        />
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <Anchor href="/rules" target="_blank">Airport rules</Anchor>

          <strong>
            <Checkbox
              style={{ marginTop: 10, marginBottom: 10 }}
              label={<span>I read the rules and agree to them</span>}
              checked={agreeChecked}
              onChange={(event) => setAgreeChecked(event.currentTarget.checked)}
            />
          </strong>
        </div>
        <Button onClick={() => submitFlightRequest()}>Request flight</Button>
      </div>
    </LoggedInContainer>
  );
};
