import { Button, Flex } from "@mantine/core";
import { Link } from "react-router-dom";

export function StartScreen() {
  return (
    <Flex
      mih={80}
      style={{ padding: 10 }}
      gap="md"
      justify="flex-start"
      align="flex-start"
      direction="row"
      wrap="wrap"
    >
      <Link style={{ color: "white", textDecoration: "none" }} to="/login">
        <Button>Login </Button>
      </Link>
      <Link
        style={{ color: "white", textDecoration: "none" }}
        to="/register-account"
      >
        <Button>Register</Button>
      </Link>
      <Link
        style={{ color: "white", textDecoration: "none" }}
        to="/register-options"
      >
        <Button>Register flight</Button>
      </Link>
    </Flex>
  );
}
