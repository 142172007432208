import { LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getPaymentStatus } from "../misc/api";
import { IconCheck, IconClock } from "@tabler/icons-react";

export const ProcessPayment = () => {
  const [visible, { toggle }] = useDisclosure(true);
  const { search } = useLocation();
  const [pstatus, setPStatus] = useState(null);
  const queryParams = new URLSearchParams(search);
  const order_reference = queryParams.get("order_reference");
  const payment_reference = queryParams.get("payment_reference");

  useEffect(async () => {
    if (order_reference && payment_reference) {
      const { status } = await getPaymentStatus({
        order_reference,
        payment_reference,
      });
      setPStatus(status);
      toggle();

      if (status === "win") {
        // Redirect after 5 seconds
        setTimeout(() => {
          window.location.href = "/main";
        }, 5000);
      } else {
        // Refresh after 15 seconds
        setTimeout(() => {
          window.location.reload();
        }, 30000);
      }
    } else {
      window.location.href = "/main";
    }
  }, []);

  return (
    <div>
      <LoadingOverlay visible={visible} overlayBlur={2} />
      {pstatus === "win" ? (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <IconCheck
            color="white"
            style={{
              backgroundColor: "limegreen",
              padding: 10,
              borderRadius: 90,
            }}
            size={60}
          />
          <h3>Payment successful</h3>
          <p>Redirecting in 5 seconds...</p>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <IconClock
            color="white"
            style={{
              backgroundColor: "dodgerblue",
              padding: 10,
              borderRadius: 90,
            }}
            size={60}
          />
          <h3>Payment pending</h3>
          <p>Trying again in 30 seconds...</p>
        </div>
      )}
    </div>
  );
};
