import { Badge, Button, Modal, Select, Table, TextInput } from "@mantine/core";
import { LoggedInContainer } from "../Components/LoggedInContainer";
import { useEffect, useState } from "react";
import { createAirplane, getUserAirplanes, saveAirplane } from "../misc/api";

export const PlaneScreen = () => {
  const [elements, setElements] = useState([]);
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    getUserAirplanes().then((x) => {
      setElements(x);
    });
  }, [visible, modal]);

  const rows = elements.map((element) => (
    <tr key={element.plane_id}>
      <td>{element.plane_public_name}</td>
      <td>{element.plane_name}</td>
      <td>{element.plane_type}</td>
      <td>{element.status}</td>
    </tr>
  ));
  const submitNewAirplane = () => {
    let submit = modal;
    if (submit.status == null) {
      submit.status = "active";
    }
    if (submit.plane_id) {
      saveAirplane(submit);
    } else {
      createAirplane(submit);
    }
    setModal(null);
    setVisible(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    // Convert input to uppercase
    const upperValue = value.toUpperCase();
    // Regular expression to match only uppercase letters and numbers
    const regex = /^[A-Z0-9]*$/;
    // Check if the converted value matches the regex and its length is <= 8
    if (regex.test(upperValue) && upperValue.length <= 8) {
      setModal({ ...modal, plane_name: upperValue });
    }
  };

  return (
    <LoggedInContainer>
      <Modal
        opened={visible}
        onClose={() => setVisible(false)}
        title={modal?.plane_id ? "Edit aircraft" : "Create aircraft"}
      >
        <TextInput
          onChange={handleChange}
          value={modal?.plane_name}
          description="Max 8 letters/numbers"
          label="aircraft registration"
        />
        <TextInput
          onChange={(x) =>
            setModal({ ...modal, plane_type: x.target.value?.toUpperCase() })
          }
          value={modal?.plane_type}
          label="ICAO"
        />
        <TextInput
          onChange={(x) =>
            setModal({ ...modal, plane_public_name: x.target.value })
          }
          value={modal?.plane_public_name}
          label="aircraft name"
          description="Display name, as you want"
        />
        <Select
          label="Status"
          placeholder="Select one"
          value={modal?.status ? modal?.status : "active"}
          onChange={(x) => setModal({ ...modal, status: x })}
          data={[
            { value: "active", label: "Active" },
            { value: "disabled", label: "Disabled" },
          ]}
        />
        <div style={{ marginTop: 20 }}>
          <Button onClick={() => submitNewAirplane()}>Apstiprināt</Button>
        </div>
      </Modal>
      <Button onClick={() => setVisible(true)}>Add aircraft</Button>
      <Table>
        <thead>
          <tr>
            <th>Aircraft name</th>
            <th>Aircraft registration</th>
            <th>ICAO</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </LoggedInContainer>
  );
};
