import { Modal, Table } from "@mantine/core";
import { LoggedInContainer } from "../../Components/LoggedInContainer";
import { useEffect, useState } from "react";
import { FlightRows } from "../FlightHistory";
import { getUserRequestsAdmin } from "../../misc/api";

export const AdminRequestScreen = () => {
  const [elements, setElements] = useState([]);
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    getUserRequestsAdmin().then((x) => {
      setElements(x);
    });
  }, [refresh]);

  return (
    <LoggedInContainer>
      <h3>Pieprasījumi</h3>
      {!elements.error && (
        <FlightRows
          elements={elements && !elements.error && elements}
          allowModal={true}
          moreDefailts={true}
          setRefresh={setRefresh}
        />
      )}
    </LoggedInContainer>
  );
};
