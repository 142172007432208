import { useCallback, useState } from "react";
import {
  Stepper,
  Button,
  Group,
  Input,
  TextInput,
  Textarea,
  Select,
  CopyButton,
  Tooltip,
  ActionIcon,
} from "@mantine/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { TooltipIcon } from "../Components/TooltipIcon";
import {
  getPaymentLink,
  requestSingleFlight,
  sentVerifyNumberRequest,
  verifyReceivedSMSCode,
} from "../misc/api";
import { QuestionModal } from "../Components/QuestionModal";
import { AirplaneRules } from "../Components/AirplaneRules";
import { DateInput, TimeInput } from "@mantine/dates";
import { SelectItem } from "../Components/SelectItem";
import { validateEmail } from "../misc/functions";

export function RegisterSingleScreen() {
  const [request, setRequest] = useState([]);
  const [phone, setPhone] = useState();
  const [phoneValid, setPhoneValid] = useState(false);
  const [active, setActive] = useState(0);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [ruleTestModal, setRuleTestModal] = useState(false);
  const [sentToVerify, setSentToVerify] = useState(false);
  const [verifyCode, setVerifyCode] = useState();
  const [error, setError] = useState(null);

  const saveRequestAndMoveOn = async () => {
    const request_flight = await requestSingleFlight({
      ...request,
      requester_number: phone,
    });
    nextStep();
  };

  const saveRequestAndGetPaymentLink = async () => {
    const request_flight = await requestSingleFlight({
      ...request,
      requester_number: phone,
    });

    const request_id = request_flight[0].request_id;
    const paymentLink = await getPaymentLink({
      cost: "20",
      product: "ot",
      user_nr: phone,
      request_id: request_id,
      valid: null,
    });
    window.location.href = paymentLink.link;
  };

  const handleVerify = async () => {
    if (sentToVerify == false) {
      setSentToVerify(true);
      sentVerifyNumberRequest(phone);
    } else {
      if (verifyCode.trim().length == 6) {
        const response = await verifyReceivedSMSCode(phone, verifyCode);
        if (response.status == "approved") {
          setError(null);
          setPhoneValid(true);
          nextStep();
        } else {
          setError("Try again!");
        }
      } else {
        setError("Wrong input length!");
      }
    }
  };

  const handleInputChange = useCallback(
    (fieldName, newValue) => {
      setRequest((prevState) => ({
        ...prevState,
        [fieldName]: newValue,
      }));
    },
    [request]
  );

  const checkIfValid = (data) => {
    return data && data.trim() != "" && data.trim().length > 3;
  };

  const handleStepChange = () => {
    if (active == 0) {
      if (
        request.length != 0 &&
        checkIfValid(request?.name) &&
        checkIfValid(request?.surname) &&
        checkIfValid(request?.email) &&
        checkIfValid(request?.plane_number) &&
        validateEmail(request.email)
      ) {
        nextStep();
      } else {
        window.alert(
          "Please fill all required fields and make sure all the inputs are valid!"
        );
      }
    } else {
      nextStep();
    }
  };

  const ruleTest = () => {
    setRuleTestModal(true);
  };

  const nextStep = () =>
    setActive((current) => (current < 4 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <>
      {ruleTestModal && (
        <QuestionModal
          userNr={phone}
          ruleTestModal={ruleTestModal}
          setRuleTestModal={setRuleTestModal}
          nextStep={nextStep}
        />
      )}
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Stepper active={active} onStepClick={null} breakpoint="sm">
          <Stepper.Step
            label="First step"
            description="Personal details"
            style={{ padding: 20 }}
          >
            <TooltipIcon
              required
              value={request?.name}
              onChange={(event) =>
                handleInputChange("name", event.target.value)
              }
              label="Pilot's name"
              placeholder="Pilot's name"
            />
            <TooltipIcon
              required
              value={request?.surname}
              onChange={(event) =>
                handleInputChange("surname", event.target.value)
              }
              label="Pilot's surname"
              placeholder="Pilot's surname"
            />
            <TooltipIcon
              required
              value={request?.email}
              onChange={(event) =>
                handleInputChange("email", event.target.value)
              }
              label="E-mail"
              placeholder="mail@mail.lv"
              tooltip={"E-mail we'd use to contact you"}
            />
            <TooltipIcon
              required
              value={request?.plane_number}
              onChange={(event) =>
                handleInputChange("plane_number", event.target.value)
              }
              label="Airplane number"
              placeholder="XXXXX"
            />
            <TooltipIcon
              value={request.departure_place}
              onChange={(event) =>
                handleInputChange("departure_place", event.target.value)
              }
              label="Origin airport"
              placeholder="RIX"
            />
            <TooltipIcon
              value={request.arrival_place}
              onChange={(event) =>
                handleInputChange("arrival_place", event.target.value)
              }
              label="Arrival airport"
              placeholder="RIX"
            />
            <Select
              label="PPR type"
              itemComponent={SelectItem}
              value={request.ppr_type}
              onChange={(event) => handleInputChange("ppr_type", event)}
              data={[
                {
                  value: 0,
                  label: "(typical for guests) Single arrival",
                  description:
                    "Valid also for a departure within 3 days starting with the date of arrival",
                },
                {
                  value: 1,
                  label: "(typical for locals) One flying day",
                  description:
                    "One flying day out of Spilve for unlimited number of flights",
                },
                {
                  value: 2,
                  label: "Single departure",
                  description:
                    "Valid also for an arrival within 3 days starting with the day of departure",
                },
              ]}
            />
            <DateInput
              value={request.dof}
              onChange={(v) => handleInputChange("dof", v)}
              label="Date of flight"
              placeholder="YYMMDD"
              valueFormat="YYMMDD"
            />
            {/* <TimeInput
              value={request.departure_time}
              onChange={(event) =>
                handleInputChange("departure_time", event.target.value)
              }
              label="Planned time of departure"
            />
            <TimeInput
              value={request.flight_lenght}
              onChange={(event) =>
                handleInputChange("flight_lenght", event.target.value)
              }
              label="Lenght of flight"
            /> */}
            <Textarea
              label="Remarks about the flight"
              value={request.free}
              onChange={(event) =>
                handleInputChange("free", event.target.value)
              }
            />
          </Stepper.Step>
          <Stepper.Step
            label="Second step"
            description="Verify phone number"
            style={{ padding: 20 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {/* {phoneValid ? (
                <div style={{ textAlign: "center" }}>
                  <IconCheck
                    color="white"
                    style={{
                      backgroundColor: "limegreen",
                      padding: 10,
                      borderRadius: 90,
                    }}
                    size={40}
                  />
                </div>
              ) : sentToVerify == false ? (
                <div>
                  <div style={{ marginBottom: 5 }}>
                    {" "}
                    Enter your phone number
                  </div>
                  <div>
                    <PhoneInput
                      country={"lv"}
                      value={phone}
                      onChange={(p) => setPhone(p)}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{ marginBottom: 5 }}> Enter received code:</div>
                  <div>
                    <Input
                      value={verifyCode}
                      onChange={(p) => setVerifyCode(p.target.value)}
                    />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                  </div>
                </div>
              )} */}
              <TextInput
                label="Phone number"
                value={phone}
                onChange={(x) => setPhone(x.target.value)}
              />
              <Button
                onClick={() => {
                  if (!phone) {
                    return window.alert(
                      "Ievadiet kaut kādu numuru, lai saņemtu jautājumus"
                    );
                  }
                  nextStep();
                }}
              >
                Dev only - verify number
              </Button>
            </div>
          </Stepper.Step>
          <Stepper.Step
            label="Third step"
            description="Rules"
            style={{ padding: 20 }}
          >
            <AirplaneRules
              setAgreeChecked={setAgreeChecked}
              agreeChecked={agreeChecked}
            />
          </Stepper.Step>
          <Stepper.Step
            label="Final step"
            description="Confirm & pay"
            style={{ padding: 20 }}
          >
            <div style={{ textAlign: "center" }}>
              <div>
                <strong>20.00</strong> EUR
              </div>
              {/* <Button onClick={saveRequestAndGetPaymentLink}>Pay now</Button> */}
            </div>
          </Stepper.Step>
          <Stepper.Completed>
            <div style={{ textAlign: "center" }}>
              <IconCheck
                color="white"
                style={{
                  backgroundColor: "limegreen",
                  padding: 10,
                  borderRadius: 90,
                }}
                size={60}
              />
              <h3>Success!</h3>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "0.5px solid lightgrey",
                    padding: "5px 20px",
                  }}
                >
                  <span style={{ marginRight: 10 }}>PPR 12345</span>
                  <CopyButton value="12345" timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? "Copied" : "Copy"}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? "teal" : "gray"}
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck size="1rem" />
                          ) : (
                            <IconCopy size="1rem" />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </div>
              </div>
            </div>
          </Stepper.Completed>
        </Stepper>

        <Group position="center" mt="xl" mb="xl" style={{ marginBottom: 100 }}>
          {active != 0 && active != 4 && (
            <Button variant="default" onClick={prevStep}>
              Back
            </Button>
          )}
          {active != 4 &&
            active != 3 &&
            !(active == 1 && phoneValid == false) && (
              <Button
                onClick={
                  active == 2 && agreeChecked == true
                    ? ruleTest
                    : handleStepChange
                }
                disabled={active == 2 && agreeChecked == false}
              >
                Next step
              </Button>
            )}
          {active == 1 &&
            phoneValid == false &&
            (phone?.length > 8 || verifyCode?.length > 4) && (
              <Button
                onClick={handleVerify}
                disabled={active == 2 && agreeChecked == false}
              >
                Verify {sentToVerify ? "Code" : "Number"}
              </Button>
            )}
          {active == 4 && <Button>Kaut kāda darbība</Button>}
          {active == 3 && (
            <Button onClick={saveRequestAndGetPaymentLink}>Pay now</Button>
          )}
          {active == 3 && (
            <Button onClick={saveRequestAndMoveOn}>Pay at the Airport</Button>
          )}
        </Group>
      </div>
    </>
  );
}
