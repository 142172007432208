import React, { useEffect, useState } from "react";
import { LoggedInContainer } from "../../Components/LoggedInContainer";
import { TextInput, Textarea, Button } from "@mantine/core";
import { getRules, saveRules } from "../../misc/api";

import QuillEditor from "react-quill";
// import styles from "./styles.module.css";
import "react-quill/dist/quill.snow.css";

export const AdminRules = () => {
  const [rulePairs, setRulePairs] = useState();
  const [expandedRules, setExpandedRules] = useState({});
  const [allExpanded, setAllExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState(false);

  useEffect(() => {
    getRules().then((x) => {
      setRulePairs(x.rules);
      // Initialize expanded state for each rule to false
      const initialExpandedState = {};
      x.rules.forEach((rule, index) => {
        initialExpandedState[index] = false;
      });
      setExpandedRules(initialExpandedState);
      setLoading(false);
    });
  }, []);

  const handleInputChange = (index, key, newValue) => {
    const newRulePairs = [...rulePairs];
    newRulePairs[index][key] = newValue;
    setRulePairs(newRulePairs);
  };

  const toggleRuleExpansion = (index) => {
    setExpandedRules((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const toggleAllExpansion = () => {
    setAllExpanded((prev) => !prev);
    const newExpandedState = {};
    rulePairs.forEach((_, index) => {
      newExpandedState[index] = !allExpanded;
    });
    setExpandedRules(newExpandedState);
  };

  const addRulePair = () => {
    const newRule = { title: "", content: "" };
    setRulePairs([...rulePairs, newRule]);

    // Expand the newly added rule by setting its expanded state to true
    setExpandedRules((prevExpanded) => ({
      ...prevExpanded,
      [rulePairs.length]: true,
    }));
  };
  const removeRulePair = (index) => {
    const newRulePairs = [...rulePairs];
    const ruleToRemove = newRulePairs[index];

    if (ruleToRemove.id) {
      newRulePairs[index] = { ...ruleToRemove, deleted: true };
    } else {
      newRulePairs.splice(index, 1);
    }

    setRulePairs(newRulePairs);
  };

  const saveRule = () => {
    saveRules(rulePairs);
    window.location.reload();
  };

  return (
    <LoggedInContainer>
      <div
        style={{
          display: "flex",
          zIndex: 20,
          backgroundColor: "white",
          justifyContent: "space-between",
          position: "fixed",
          padding: 10,
          width: "-webkit-fill-available",
          marginLeft: -20,
          marginTop: -20,
        }}
      >
        <h2>Rules (ENG)</h2>
        <Button
          onClick={() => toggleAllExpansion()}
          style={{ marginTop: 10, marginLeft: 10 }}
        >
          {allExpanded ? "Hide All" : "Expand All"}
        </Button>
      </div>
      <div style={{ paddingTop: 80 }}>
        {rulePairs?.map(
          (rule, index) =>
            !rule.deleted && (
              <div
                key={index}
                style={{
                  padding: 15,
                  backgroundColor: "#f1f1f1",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                {!expandedRules[index] && (
                  <div
                    onClick={() => toggleRuleExpansion(index)}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      marginBottom: 10,
                      textDecoration: "underline",
                    }}
                  >
                    {rule.title}
                  </div>
                )}
                {expandedRules[index] && (
                  <>
                    <TextInput
                      label="Virsraksts"
                      value={rule.title}
                      onChange={(event) =>
                        handleInputChange(
                          index,
                          "title",
                          event.currentTarget.value
                        )
                      }
                    />
                    <div style={{ marginTop: 15 }}>
                      <label
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: 500,
                          color: "#212529",
                        }}
                      >
                        Saturs
                      </label>
                      <div style={{ backgroundColor: "white" }}>
                        <QuillEditor
                          value={rule?.content ? rule?.content : ""}
                          onChange={(value) =>
                            handleInputChange(index, "content", value)
                          }
                        />
                      </div>
                    </div>
                    {/* <Textarea
                      label="Saturs"
                      value={rule.content}
                      autosize
                      minRows={10}
                      maxRows={20}
                      onChange={(event) =>
                        handleInputChange(
                          index,
                          "content",
                          event.currentTarget.value
                        )
                      }
                    /> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div></div>
                      <Button
                        onClick={() => removeRulePair(index)}
                        style={{
                          marginTop: 10,
                          backgroundColor: "red",
                          color: "white",
                        }}
                        variant="link"
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
        )}
      </div>
      <Button onClick={addRulePair} style={{ marginTop: 10, marginBottom: 40 }}>
        Add Rule
      </Button>
      <hr></hr>
      <div>
        <Button
          onClick={() => saveRule()}
          disabled={loading}
          style={{ marginTop: 10, paddingLeft: 50, paddingRight: 50 }}
        >
          Save
        </Button>
      </div>
    </LoggedInContainer>
  );
};
