import { Button, Modal, Card, Text, Checkbox } from "@mantine/core";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { getClientQuestions } from "../misc/api";

export const QuestionModal = ({
  ruleTestModal,
  setRuleTestModal,
  nextStep,
  userNr,
}) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (userNr) {
      getClientQuestions({ data: { client_phone: userNr } })
        .then((response) => {
          console.log(response);
          // Initialize the 'checked' property for each option
          const updatedQuestions = response.questions.map((question) => {
            try {
              const options = JSON.parse(question.options);
              const optionsWithChecked = options.map((option) => ({
                ...option,
                checked: false, // Assuming initially none are checked
              }));
              return { ...question, options: optionsWithChecked };
            } catch (error) {
              console.error(
                `Error parsing options for question "${question.question_text}":`,
                error
              );
              return { ...question, options: [] }; // Default to an empty array if parsing fails
            }
          });

          setQuestions(updatedQuestions);
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
        });
    }
  }, []);

  const handleSubmit = () => {
    const results = questions.map((question) => {
      const options = Array.isArray(question.options) ? question.options : [];
      const correctOptions = options.filter((option) => option.right);

      const checkedOptions = options.filter(
        (option) => option.checked === true
      );

      const incorrectOptions = checkedOptions.filter((checkedOption) => {
        return !correctOptions.some(
          (correctOption) => correctOption.title === checkedOption.title
        );
      });

      const isAllCorrect = correctOptions.every((correctOption) =>
        checkedOptions.some(
          (checkedOption) => checkedOption.title === correctOption.title
        )
      );

      const isNoExtraChecked = checkedOptions.every((checkedOption) =>
        options.some((option) => option.title === checkedOption.title)
      );

      if (!isAllCorrect || !isNoExtraChecked || incorrectOptions.length > 0) {
        console.log(
          `Expected answer for question "${question.question_text}":`,
          correctOptions
        );
        console.log(`Actual answer:`, checkedOptions);
      }

      return isAllCorrect && isNoExtraChecked && incorrectOptions.length === 0;
    });

    if (results.every((result) => result)) {
      alert("All answers are correct!");
      setRuleTestModal(false);
      nextStep();
    } else {
      alert(
        "Some answers are incorrect or extra options are checked. Please reread the rules and try again."
      );
      setRuleTestModal(false);
    }
  };

  const handleCheckboxChange = (questionIndex, optionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      const updatedOptions = [...updatedQuestions[questionIndex].options];
      updatedOptions[optionIndex].checked =
        !updatedOptions[optionIndex].checked;
      updatedQuestions[questionIndex].options = updatedOptions;
      return updatedQuestions;
    });
  };

  return (
    <Modal
      fullScreen
      opened={ruleTestModal}
      onClose={() => console.log("this")}
      title={<h2 style={{ marginBottom: 0 }}>Rule test</h2>}
      centered
      withCloseButton={false}
    >
      <div style={{ padding: 20, marginTop: -20 }}>
        {questions &&
          questions.map((question, questionIndex) => {
            const options = question.options;
            return (
              <Card withBorder key={questionIndex}>
                <Text>{question.question_text}</Text>
                {options.map((option, optionIndex) => (
                  <Checkbox
                    key={optionIndex}
                    label={option.title}
                    checked={option.checked || false}
                    onChange={() =>
                      handleCheckboxChange(questionIndex, optionIndex)
                    }
                  />
                ))}
              </Card>
            );
          })}
        <Button style={{ marginTop: 10 }} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};
