import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Divider,
  Anchor,
  Stack,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { setData, setGroup, setToken, setUserId } from "../misc/tokens";
import { handleLogin } from "../misc/api";

export function LoginScreen() {
  const [error, setError] = useState(null);
  //const [isLoggedIn, setLoggedin] = useState(getToken());

  let navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
    },
  });

  const handleSubmit = async (e) => {
    //e.preventDefault();
    if (form.values.email && form.values.password) {
      setError(null);
      try {
        const response = await handleLogin(
          form.values.email,
          form.values.password
        );
        if (!response.ok) {
          setError("Invalid email or password!");
          throw new Error("Incorrect email or password");
        }
        const {data, token} = await response.json();

        setToken(token);
        setGroup(data.group);
        setUserId(data.uid);
        setData(data);
        // navigate("/main");
        window.location.href="/main";
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Invalid email or password!");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "darkslategray",
        paddingTop: "10%",
        display: "flex",
        paddingBottom: 500,
      }}
    >
      <Paper
        radius="md"
        p="xl"
        withBorder
        style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Text size="lg" weight={500}>
          Welcome to Spilve
        </Text>
        <Divider label="Login with email" labelPosition="center" my="lg" />
        <form
          onSubmit={form.onSubmit((e) => {
            handleSubmit(e);
            //window.location.href = "/account";
          })}
        >
          <Stack>
            <TextInput
              required
              label="Email"
              placeholder="hello@spilve.com"
              value={form.values.email}
              onChange={(event) =>
                form.setFieldValue("email", event.currentTarget.value)
              }
              error={form.errors.email && "Invalid email"}
              radius="md"
            />
            <PasswordInput
              required
              label="Password"
              placeholder="Your password"
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={
                form.errors.password &&
                "Password should include at least 6 characters"
              }
              radius="md"
            />
          </Stack>
          {error && <span style={{ color: "red" }}>{error}!</span>}
          <Group position="apart" mt="xl">
            <Anchor
              component="button"
              type="button"
              color="dimmed"
              onClick={() => navigate("/register-account")}
              size="xs"
            >
              Don't have an account? Register
            </Anchor>
            <Button type="submit" radius="xl">
              Login
            </Button>
          </Group>
        </form>
      </Paper>
    </div>
  );
}
