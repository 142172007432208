import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Text,
  TextInput,
} from "@mantine/core";
import { LoggedInContainer } from "../Components/LoggedInContainer";
import { useEffect, useState } from "react";
import { getPaymentLink, getUserCredits } from "../misc/api";
import { getToken, getUserId } from "../misc/tokens";
import { useDisclosure } from "@mantine/hooks";

export const BuyTokenScreen = () => {
  const [credits, setCredits] = useState(null);
  const [visible, { toggle }] = useDisclosure(true);

  useEffect(() => {
    getUserCredits().then((creditObjects) => {
      creditObjects.map((credit) => {
        if (new Date(credit.valid) > new Date()) {
          setCredits(true);
        }
      });
      toggle();
    });
  }, []);

  const getPaymentLinkAndForward = async (price, product, valid) => {
    toggle();
    const user = getUserId();
    const paymentLink = await getPaymentLink({
      cost: price,
      product,
      user,
      valid,
    });
    window.location.href = paymentLink.link;
  };

  const packages = [
    {
      product: "ot",
      price: 25,
      name: "Single Arrival",
      description:
        "Typical for guests - valod also for a departure within 3 days starting with the day of arrival",
      valid: null,
    },
    {
      product: "tt",
      price: 50,
      name: "Single Departure",
      description:
        "Valid also for an arrival within 3 days starting with the day of departure",
      valid: null,
    },
    {
      product: "sd",
      price: 50,
      name: "One Flying Day",
      description:
        "Typical for locals - One flying day out of Spilve of unlimited number of flights",
      valid: null,
    },
    !credits && {
      product: "ms",
      price: 100,
      name: "Month subscription",
      description: "Fly up to 30 days",
      valid: "30 days",
    },
    !credits && {
      product: "tms",
      price: 270,
      name: "3 Month subscription",
      description: "Fly up to 90 days",
      valid: "90 days",
    },
    !credits && {
      product: "sms",
      price: 480,
      name: "6 Month subscription",
      description: "Fly up to 180 days",
      valid: "180 days",
    },
    !credits && {
      product: "ys",
      price: 900,
      name: "1 Year subscription",
      description: "Fly up to 1 year",
      valid: "365 days",
    },
  ].filter(Boolean);

  return (
    <LoggedInContainer>
      <LoadingOverlay visible={visible} overlayBlur={2} />
      <h3>Available Credits:</h3>
      <Grid style={{ marginBottom: 50 }}>
        {packages.map((item) => (
          <Grid.Col lg={4}>
            <Card withBorder radius="md">
              <Group justify="space-between" mt="xxs">
                <div style={{ width: "100%" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Text fw={500} style={{ height: 40 }}>
                      {item.name}
                    </Text>
                    <Badge variant="outline" style={{ float: "left" }}>
                      {item.price} EUR
                    </Badge>
                  </div>
                  <Text fz="xs" c="dimmed">
                    {item.description}
                  </Text>
                </div>
              </Group>

              <Card.Section>
                <Group gap={30} style={{ padding: 15 }}>
                  <Button
                    radius="xl"
                    style={{ flex: 1 }}
                    onClick={() =>
                      getPaymentLinkAndForward(
                        item.price,
                        item.product,
                        item.valid
                      )
                    }
                  >
                    Pay now
                  </Button>
                </Group>
              </Card.Section>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
      {/* <div>
        <Button onClick={getPaymentLinkAndForward}>Proceed to payment</Button>
      </div> */}
    </LoggedInContainer>
  );
};
