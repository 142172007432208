import { useEffect, useState } from "react";
import { LoggedInContainer } from "../../Components/LoggedInContainer";
import { adminGetUsers, adminSaveUser } from "../../misc/api";
import { Table, Modal, TextInput, Select, Button } from "@mantine/core";

export const AdminUsers = () => {
  const [users, setUsers] = useState(null);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    adminGetUsers().then((x) => setUsers(x));
  }, [modal]);

  const saveUser = () => {
    adminSaveUser(modal);
    setModal(null);
  };

  const rows = users?.map((element) => (
    <tr onClick={() => setModal(element)} key={element.uid} style={{cursor: "pointer"}}>
      <td>{element.email}</td>
      <td>{element.phone}</td>
      <td>{element.name}</td>
      <td>{element.ppr}</td>
      <td>{element.group == 0 ? "Admin" : "Lietotājs"}</td>
      <td>
        {element.registered
          ? new Date(element.registered).toLocaleString()
          : null}
      </td>
    </tr>
  ));

  return (
    <LoggedInContainer>
      <Modal
        opened={modal}
        onClose={() => setModal(null)}
        title="Apstrādāt lietotāju"
      >
        <TextInput
          label="Lietotāja Vārds"
          onChange={(x) => setModal({ ...modal, name: x.target.value })}
          value={modal?.name}
        />
        <TextInput
          label="Lietotāja tel nr."
          onChange={(x) => setModal({ ...modal, phone: x.target.value })}
          value={modal?.phone}
        />
        <TextInput
          label="Lietotāja e-pasts"
          onChange={(x) => setModal({ ...modal, email: x.target.value })}
          value={modal?.email}
        />
        <TextInput
          label="Lietotāja PPR"
          onChange={(x) => setModal({ ...modal, ppr: x.target.value })}
          value={modal?.ppr}
        />
        <Select
          label="Lietotāja grupa"
          placeholder="Izvēlies vienu"
          value={modal?.group}
          onChange={(x) => setModal({ ...modal, group: x })}
          data={[
            { value: "0", label: "Admins" },
            { value: "1", label: "Lietotājs" },
            { value: "100", label: "Maksājuma pārvaldība" },
          ]}
        />
        <Select
          label="Lietotāja status"
          placeholder="Izvēlies vienu"
          value={modal?.status ? modal?.status : "active"}
          onChange={(x) => setModal({ ...modal, status: x })}
          data={[
            { value: "active", label: "aktīvs" },
            { value: "disabled", label: "bloķēts" },
          ]}
        />
        <div style={{ marginTop: 20 }}>
          <Button onClick={() => saveUser()}>Apstiprināt</Button>
        </div>
      </Modal>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Lietotāji</h2>
        {/* <Button onClick={() => setModal([])}>Add Payment</Button> */}
      </div>
      <Table>
        <thead>
          <tr>
            <th>E-pasts</th>
            <th>Tel.nr</th>
            <th>Vārds</th>
            <th>PPR</th>
            <th>Grupa</th>
            <th>Reģistrējās</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </LoggedInContainer>
  );
};
