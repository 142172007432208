import {
  Button,
  Group,
  Container,
  Title,
  List,
  ThemeIcon,
  Text,
  rem,
} from "@mantine/core";
import "react-phone-input-2/lib/style.css";
import { IconCheck } from "@tabler/icons-react";
import classes from "./RegisterHero.module.css";

export function RegisterSelectionScreen() {
  return (
    <div style={{ marginTop: 150 }}>
      <Container size="md">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title style={{ fontSize: 28 }} className={classes.title}>
              If you're often flying in Spilve
            </Title>
            <Text c="dimmed" mt="md">
              Please consider registering on our site for an easier application,
              payment, better flight control & experience!
            </Text>

            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={
                <ThemeIcon size={20} radius="xl">
                  <IconCheck
                    style={{ width: rem(12), height: rem(12) }}
                    stroke={1.5}
                  />
                </ThemeIcon>
              }
            >
              <List.Item>
                <b>Register once</b> and easily re-apply for a PPR whenever
                needed
              </List.Item>
              <List.Item>
                <b>Flight credits & subscriptions</b> – get better deals on
                multiple flight packages!
              </List.Item>
              <List.Item>
                <b>Invoices</b> – keep a track of your payments
              </List.Item>
            </List>

            <Group mt={30}>
              <Button
                onClick={() => (window.location.href = "/register-account")}
                radius="xl"
                size="md"
                variant="default"
                className={classes.control}
              >
                Proceed to register
              </Button>
              <Button
                onClick={() => (window.location.href = "/register-single")}
                variant="default"
                radius="xl"
                size="md"
                className={classes.control}
              >
                Proceed without registering
              </Button>
            </Group>
          </div>
          {/* <Image src={image.src} className={classes.image} /> */}
        </div>
      </Container>
    </div>
  );
}
