import "./App.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LoginScreen } from "./Screens/LoginScreen";
import { RegisterAccountScreen } from "./Screens/RegisterScreen";
import { StartScreen } from "./Screens/StartScreen";
import { AccountScreen } from "./Screens/AccountScreen";
import { RequestFlightScreen } from "./Screens/RequestFlightScreen";
import { LoggedInContainer } from "./Components/LoggedInContainer";
import { PaymentHistory } from "./Screens/PaymentHistory";
import { AdminRequestScreen } from "./Screens/Admin/AdminRequestScreen";
import { AdminQuestions } from "./Screens/Admin/AdminQuestions";
import { AdminRules } from "./Screens/Admin/AdminRules";
import { AdminUsers } from "./Screens/Admin/AdminUsers";
import { AdminPayments } from "./Screens/Admin/AdminPayments";
import { AdminScreen } from "./Screens/Admin/AdminScreen";
import { FlightHistory } from "./Screens/FlightHistory";
import { RegisterSingleScreen } from "./Screens/RequestSingleFlightScreen";
import { AirplaneRules } from "./Components/AirplaneRules";
import { RegisterSelectionScreen } from "./Screens/RegisterSelectionScreen";
import {
  getGroup,
  getToken,
  removeData,
  removeGroup,
  removeToken,
  removeUserId,
} from "./misc/tokens";
import { useState } from "react";
import { BuyTokenScreen } from "./Screens/BuyTokensScreen";
import FlightScreen from "./Screens/FlightScreen";
import { ProcessPayment } from "./Screens/ProcessPayment";
import { PlaneScreen } from "./Screens/PlaneScreen";

function App() {
  const [isLoggedIn, setLoggedin] = useState(getToken());
  const [chx, setChx] = useState(false);
  const navigation = useNavigate();

  const admin = getGroup() == 0 ? true : false;

  const Logout = () => {
    removeToken();
    removeGroup();
    removeData();
    removeUserId();
    setLoggedin(false);
    // window.location.reload();
    return <Navigate to="/login" />;
  };

  const RedirectWrapper = () => (
    <Navigate to={isLoggedIn ? "/main" : "/login"} />
  );

  return (
    <Routes>
      <Route exct path="/" element={<StartScreen />} />
      <Route
        exct
        path="/login"
        element={isLoggedIn ? <RedirectWrapper /> : <LoginScreen />}
      />
      {isLoggedIn && (
        <>
          <Route exct path="/request" element={<RequestFlightScreen />} />
          <Route exct path="/flight-data" element={<FlightScreen />} />
          <Route exct path="/payment-history" element={<PaymentHistory />} />
          <Route exct path="/flight-history" element={<FlightHistory />} />
          <Route exct path="/main" element={<AccountScreen />} />
          <Route exct path="/buy-credits" element={<BuyTokenScreen />} />
          <Route exct path="/planes" element={<PlaneScreen />} />
        </>
      )}
      <Route
        exct
        path="/register-account"
        element={<RegisterAccountScreen />}
      />
      <Route exct path="/register-single" element={<RegisterSingleScreen />} />
      <Route
        exct
        path="/rules"
        element={
          <LoggedInContainer setTr={setChx} tr={chx}>
            {/* use token & db to see if rules have been agreed upon */}
            <AirplaneRules
              showRules={false}
              style={{ marginTop: 100 }}
              showCheckmark={chx}
            />
          </LoggedInContainer>
        }
      />
      <Route
        exct
        path="register-options"
        element={<RegisterSelectionScreen />}
      />
      <Route exct path="/e-pay/" element={<ProcessPayment />} />

      {admin && (
        <>
          <Route exct path="/admin" element={<AdminScreen admin={true} />} />
          <Route exct path="/admin-requests" element={<AdminRequestScreen />} />
          <Route exct path="/admin-questions" element={<AdminQuestions />} />
          <Route exct path="/admin-rules" element={<AdminRules />} />
          <Route exct path="/admin-users" element={<AdminUsers />} />
          <Route exct path="/admin-payments" element={<AdminPayments />} />
        </>
      )}
      <Route exct path="/logout" element={<Logout />} />
      <Route path="*" element={<RedirectWrapper />} />
    </Routes>
  );
}

export default App;
