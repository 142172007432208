import { Button, Flex, Grid, Indicator, rem } from "@mantine/core";
import { LoggedInContainer } from "../../Components/LoggedInContainer";
import { useEffect, useState } from "react";
import { getUserRequestCountAdmin } from "../../misc/api";

export const AdminScreen = ({ admin }) => {
  const [request, setRequest] = useState(null);

  useEffect(() => {
    getUserRequestCountAdmin().then((x) => {
      setRequest(x);
    });
  }, []);

  return (
    <LoggedInContainer admin={admin}>
      <Grid style={{ marginTop: 40 }}>
        <Grid.Col xs={4} style={{ padding: 15, minHeight: rem(50) }}>
          <Indicator
            inline
            label={request}
            style={{ width: "100%" }}
            size={request > 0 ? 16 : 0}
          >
            <Button
              variant="gradient"
              style={{ width: "100%" }}
              gradient={{ from: "orange", to: "red" }}
              onClick={() => (window.location.href = "/admin-requests")}
            >
              Pieprasījumu pārvaldība
            </Button>
          </Indicator>
        </Grid.Col>
        <Grid.Col xs={4} style={{ padding: 15, minHeight: rem(50) }}>
          <Button
            variant="gradient"
            style={{ width: "100%" }}
            gradient={{ from: "indigo", to: "cyan" }}
            onClick={() => (window.location.href = "/admin-rules")}
          >
            Apstrādāt likumus
          </Button>
        </Grid.Col>
        <Grid.Col xs={4} style={{ padding: 15, minHeight: rem(50) }}>
          <Button
            variant="gradient"
            style={{ width: "100%" }}
            gradient={{ from: "teal", to: "lime", deg: 105 }}
            onClick={() => (window.location.href = "/admin-questions")}
          >
            Apstrādāt jautājumus
          </Button>
        </Grid.Col>
        <Grid.Col xs={4} style={{ padding: 15, minHeight: rem(50) }}>
          <Button
            variant="gradient"
            style={{ width: "100%" }}
            gradient={{ from: "teal", to: "blue", deg: 60 }}
            onClick={() => (window.location.href = "/admin-users")}
          >
            Lietotāju pārvaldība
          </Button>
        </Grid.Col>

        <Grid.Col xs={4} style={{ padding: 15, minHeight: rem(50) }}>
          <Button
            variant="gradient"
            style={{ width: "100%" }}
            gradient={{ from: "#ed6ea0", to: "#ec8c69", deg: 35 }}
            onClick={() => (window.location.href = "/admin-payments")}
          >
            Maksājumu pārvaldība
          </Button>
        </Grid.Col>
      </Grid>
    </LoggedInContainer>
  );
};
