import { useEffect, useState } from "react";
import { LoggedInContainer } from "../Components/LoggedInContainer";
import { Badge, Button, Modal, Table } from "@mantine/core";
import {
  cancelUserFlight,
  confirmUserFlight,
  getUserRequests,
} from "../misc/api";
import { formatDateToYYmmDD } from "../misc/time";
import { IconArrowMoveRight, IconArrowRight } from "@tabler/icons-react";

export const FlightHistory = () => {
  const [flights, setFlights] = useState([]);

  useEffect(() => {
    getUserRequests().then((x) => {
      setFlights(x);
    });
  }, []);

  return (
    <LoggedInContainer>
      <FlightRows
        elements={flights && !flights.error ? flights : []}
        allowModal={false}
      />
    </LoggedInContainer>
  );
};

export const FlightRows = ({
  elements,
  allowModal = false,
  moreDefailts = false,
  setRefresh,
}) => {
  const [modal, setModal] = useState(null);

  const confirmFlight = (id) => {
    if (window.confirm("Are you sure you want to confirm this request?")) {
      confirmUserFlight({ id: id });
      setModal(null);
      setRefresh && setRefresh(id);
    }
  };

  const declineFlight = (id) => {
    if (window.confirm("Are you sure you want to decline this request?")) {
      cancelUserFlight({ id: id });
      setModal(null);
      setRefresh && setRefresh(id);
    }
  };

  const findHighestValidDate = (creditObjects) => {
    if (!creditObjects || creditObjects.length === 0) {
      return null; // Return null if the array is empty or undefined
    }

    let highestValidDate = null;

    for (const credit of creditObjects) {
      if (
        credit.valid &&
        (highestValidDate === null || credit.valid > highestValidDate)
      ) {
        highestValidDate = credit.valid;
      }
    }

    if (highestValidDate !== null) {
      const formattedDate = new Date(highestValidDate).toLocaleDateString(
        undefined,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );

      return <div>Subscription paid till: {formattedDate}</div>;
    } else {
      return (
        <div>
          Paid for:{" "}
          {creditObjects[0].product == "tt" ? "2 flights" : "1 flight"}
          {creditObjects[1] && <small>(more purchased tokens Available)</small>}
        </div>
      );
    }
  };

  const rows = elements?.map((element, index) => (
    <tr
      key={index}
      onClick={() => (allowModal ? setModal(element) : null)}
      style={{
        cursor: "pointer",
        backgroundColor:
          element.status == "confirmed"
            ? "#00800020"
            : element.status == "cancelled"
            ? "#ff000020"
            : "",
      }}
    >
      <td>{index + 1}</td>
      {moreDefailts && (
        <td>
          {element.requester_email
            ? element.requester_email
            : element.email
            ? element.email
            : ""}
        </td>
      )}
      {moreDefailts && (
        <td>
          {element.requester_number
            ? element.requester_number
            : element.phone
            ? element.phone
            : ""}
        </td>
      )}
      <td>{element.departure_place}</td>
      <td>{element.arrival_place}</td>
      <td>{formatDateToYYmmDD(new Date(element.dof))}</td>
      <td>{element.departure_time}</td>
      <td>{element.flight_lenght}</td>
      {/* <td>{element.fl_paid}</td> */}
      <td>
        <Badge
          color={
            element?.status == "confirmed"
              ? "green"
              : element?.status == "cancelled"
              ? "red"
              : ""
          }
        >
          {element.status}
        </Badge>
      </td>
      <td>
        {element.ppr
          ? element.ppr
          : !element.user_id && `98${element.request_id}`}
      </td>
    </tr>
  ));

  return (
    <>
      <Modal
        opened={modal}
        onClose={() => setModal(null)}
        title={"Flight request"}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>DOF: {formatDateToYYmmDD(new Date(modal?.dof))}</span>
          <Badge
            color={
              modal?.status == "confirmed"
                ? "green"
                : modal?.status == "cancelled"
                ? "red"
                : ""
            }
          >
            {modal?.status}
          </Badge>
        </div>
        <hr />
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {modal?.departure_place}{" "}
            <IconArrowRight
              size="25"
              strokeWidth={0.5}
              style={{ marginBottom: -6 }}
            />{" "}
            {modal?.arrival_place}
            <div>
              Departure time: {modal?.departure_time}
              <br />
              Flight duration:{modal?.flight_lenght}
            </div>
          </div>
        </div>
        {modal?.payment_type == "cashier"
          ? "Samaksāts kasē"
          : modal?.credits
          ? findHighestValidDate(modal?.credits)
          : "No payment found"}
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          Contact:
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              {modal && modal?.requester_number
                ? modal?.requester_number
                : modal?.phone
                ? modal?.phone
                : ""}
            </span>
            <span>
              {modal && modal?.requester_email
                ? modal?.requester_email
                : modal?.email
                ? modal?.email
                : ""}
            </span>
          </div>
        </div>
        {modal && modal?.status == "pending" && (
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => confirmFlight(modal && modal.request_id)}
              style={{ backgroundColor: "green" }}
            >
              Confirm flight
            </Button>{" "}
            <Button
              onClick={() => declineFlight(modal && modal.request_id)}
              style={{ backgroundColor: "red" }}
            >
              Decline flight
            </Button>
          </div>
        )}
      </Modal>
      <Table>
        <thead>
          <tr>
            <th>nr.</th>
            {moreDefailts && <th>E-mail</th>}
            {moreDefailts && <th>Phone</th>}
            <th>Dep</th>
            <th>Arr</th>
            <th>DOF</th>
            <th>Dep time</th>
            <th>Lenght</th>
            {/* <th>Apmaksa</th> */}
            <th>Status</th>
            <th>PPR</th>
          </tr>
        </thead>
        <tbody>{elements && !elements?.error && rows}</tbody>
      </Table>
    </>
  );
};
