export function formatHumanFriendlyDate(yymmdd) {
  // Extract year, month, and day from the input string
  const year = Number("20" + yymmdd.substring(0, 2));
  const month = Number(yymmdd.substring(2, 4));
  const day = Number(yymmdd.substring(4, 6));

  // Create a new Date object with the extracted year, month, and day
  const date = new Date(year, month - 1, day);

  // Format the date to a more human-friendly format (e.g., "July 26, 2023")
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString(undefined, options);

  return formattedDate;
}

export const getGrinTime = () => {
  const now = new Date();

  const hours = now.getUTCHours().toString().padStart(2, "0");
  const minutes = now.getUTCMinutes().toString().padStart(2, "0");
  const seconds = now.getUTCSeconds().toString().padStart(2, "0");

  const gmtTime = `${hours}:${minutes}:${seconds}`;
  return gmtTime;
};

export const getTimeDifference = (time1, time2) => {
  const getTimeInMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const time1Minutes = getTimeInMinutes(time1);
  const time2Minutes = getTimeInMinutes(time2);

  return time2Minutes - time1Minutes;
};

export function addTimes(time1, time2) {
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const totalSeconds = timeToSeconds(time1) + timeToSeconds(time2);
  const newTime = new Date(totalSeconds * 1000).toISOString().substr(11, 8);

  return newTime;
}

export function isYymmddBeforeToday(date) {
  return date < getYYmmDD();
}

export function getYYmmDD() {
  const currentDate = new Date();
  const currentYear = String(currentDate.getFullYear()).slice(-2);
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const YYmmDD = currentYear + currentMonth + currentDay;
  return YYmmDD;
}

// Convert duration in "hh:mm:ss" format to seconds for easier comparison
export const durationToSeconds = (durationString) => {
  const [hours, minutes, seconds] = durationString.split(":");
  return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
};

// Convert time in "hh:mm" format to seconds for easier comparison
export const timeToSeconds = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  return parseInt(hours) * 3600 + parseInt(minutes) * 60;
};

export const toDateObject = (dateString) => {
  const year = parseInt("20" + dateString.substr(0, 2));
  const month = parseInt(dateString.substr(2, 2)) - 1;
  const day = parseInt(dateString.substr(4, 2));
  return new Date(year, month, day);
};

export const formatDateToYYmmDD = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2);

  return `${day}${month}${year}`;
};