import { Anchor } from "@mantine/core";
import { LoggedInContainer } from "../Components/LoggedInContainer";
import { useEffect, useState } from "react";
import { getUserCredits } from "../misc/api";
import { getData } from "../misc/tokens";

export const AccountScreen = () => {
  const [credits, setCredits] = useState(null);
  const [user, setUser] = useState(getData());
  const [tr, setTr] = useState();

  useEffect(() => {
    getUserCredits().then((x) => {
      setCredits(x);
    });
  }, []);

  const findHighestValidDate = (creditObjects) => {
    if (!creditObjects || creditObjects.length === 0) {
      return null; // Return null if the array is empty or undefined
    }

    let highestValidDate = null;

    for (const credit of creditObjects) {
      if (
        credit.valid &&
        (highestValidDate === null || credit.valid > highestValidDate)
      ) {
        highestValidDate = credit.valid;
      }
    }

    if (highestValidDate !== null) {
      const formattedDate = new Date(highestValidDate).toLocaleDateString(
        undefined,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );

      return (
        <div>
          Enjoy your subscription and fly all you want until {formattedDate}
        </div>
      );
    }

    return null; // Return null if all valids are null
  };

  return (
    <LoggedInContainer setTr={setTr}>
      <h1 style={{ marginBottom: 0 }}>Welcome {user.name}</h1>
      <div style={{ fontSize: 17, color: "grey", marginBottom: 25 }}>
        {user.email}
      </div>
      {tr != true && credits?.length == 0 ? (
        <div>
          <div>
            <span style={{ fontSize: 20, fontWeight: 400 }}>
              You have <span style={{ color: "red" }}>0 credits</span> left!
            </span>
          </div>
          <span style={{ marginTop: 5 }}>
            Purchase more credits{" "}
            <Anchor onClick={() => (window.location.href = "/buy-credits")}>
              here!
            </Anchor>
          </span>
        </div>
      ) : (
        findHighestValidDate(credits)
      )}
      {tr == true && (
        <h3 style={{ marginTop: 20 }}>
          Please read and accept the rules{" "}
          <a style={{ color: "limegreen" }} href="/rules">
            here
          </a>
          , before using the system!
        </h3>
      )}
    </LoggedInContainer>
  );
};
