// import "../App.css";
import {
  ArrowMoveRight,
  Check,
  ClockPause,
  PlaneArrival,
  PlaneDeparture,
  PlaneInflight,
  PlaneOff,
} from "tabler-icons-react";
import { Card, Button, Modal, Title, Anchor } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { getFlights, getUserCredits } from "../misc/api";
import {
  formatHumanFriendlyDate,
  getGrinTime,
  getTimeDifference,
  addTimes,
  isYymmddBeforeToday,
  getYYmmDD,
  timeToSeconds,
  toDateObject,
} from "../misc/time";
import { LoggedInContainer } from "../Components/LoggedInContainer";

const getIconByTime = (flightTime, departureTime, dof) => {
  const arrivalTime = addTimes(departureTime, flightTime);
  const currentTime = new Date().toUTCString().slice(-12, -7);
  const minutesBeforeArrival = getTimeDifference(currentTime, arrivalTime);
  const minutesAfterDeparture = getTimeDifference(departureTime, currentTime);
  const minutesAfterArrival = getTimeDifference(arrivalTime, currentTime);

  if (minutesAfterDeparture > 15 && minutesBeforeArrival > -15) {
    return <PlaneInflight size="35" />;
  } else if (minutesAfterDeparture < 15 && minutesAfterDeparture > 0) {
    return <PlaneDeparture size="35" />;
  } else if (minutesBeforeArrival < -15) {
    return <PlaneArrival size="35" />;
  } else {
    return <ClockPause size="35" />;
  }
};

const ModalElement = ({ msg }) => {
  return (
    <div
      style={
        msg
          ? { borderTop: "1px solid black", paddingTop: 5, paddingBottom: 5 }
          : {}
      }
    >
      {msg}
    </div>
  );
};

function FlightScreen() {
  const [flights, setFlights] = useState([]);
  const [grin, setGrin] = useState(getGrinTime());
  const [modal, setModal] = useState();
  const [opened, { open, close }] = useDisclosure(false);
  const [sorter, setSorter] = useState("def");
  const [sortOrder, setSortOrder] = useState("asc"); // Initial sort order is ascending ('asc')

  const [credits, setCredits] = useState(null);

  useEffect(() => {
    getUserCredits().then((x) => {
      console.log(x[0]);
      if (x[0]?.product == "tms") return setCredits(true);
      if (x[0]?.product == "sms") return setCredits(true);
      if (x[0]?.product == "ys") return setCredits(true);
      return setCredits(false);
    });
  }, []);

  const modalCall = (data) => {
    setModal(data);
    //data && data.length > 0 && open();
    open();
  };

  const sortByDeparture = (a, b) => {
    return sortOrder === "asc"
      ? a.departure_time.localeCompare(b.departure_time)
      : b.departure_time.localeCompare(a.departure_time);
  };

  // Sorting function based on arrival time
  const sortByArrival = (a, b) => {
    const aTimeOfFlight =
      timeToSeconds(a.arrival_time) + timeToSeconds(a.departure_time);
    const bTimeOfFlight =
      timeToSeconds(b.arrival_time) + timeToSeconds(b.departure_time);
    const aDeparture = a.departure_time;
    const bDeparture = b.departure_time;
    const aDof = new Date(a.dof);
    const bDof = new Date(b.dof);

    return sortOrder === "asc"
      ? bTimeOfFlight - aTimeOfFlight || aDeparture.localeCompare(bDeparture)
      : aTimeOfFlight - bTimeOfFlight || aDeparture.localeCompare(bDeparture);
  };

  // Sorting function based on date of flight (DOF) in descending order
  const sortByDof = (a, b) => {
    const aDof = toDateObject(a.dof);
    const bDof = toDateObject(b.dof);

    return bDof - aDof;
  };

  const handleSortByDeparture = () => {
    setSorter("dep");
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSortByArrival = () => {
    setSorter("arr");
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    // Function to fetch flights data
    const getFlightsData = async () => {
      try {
        const data = await getFlights();
        const sortedArray = [...data];

        if (sorter === "dep") {
          sortedArray.sort(sortByDeparture); // Sort by Arrival Time as secondary sort
        } else if (sorter === "arr") {
          sortedArray.sort(sortByArrival);
        }
        sortedArray.sort(sortByDof); // Sort by DOF first to ensure it's in descending order

        setFlights(sortedArray);
        // Sort the flights immediately after setting the data based on the selected sorter
      } catch (error) {
        // Handle error if needed
        console.error("Error fetching flights:", error);
      }
    };
    const updateGrinTime = () => {
      setGrin(getGrinTime());
    };
    if (credits) {
      getFlightsData();
    }

    const flightsIntervalId = setInterval(getFlightsData, 30000);
    const grinIntervalId = setInterval(updateGrinTime, 30000);

    return () => {
      clearInterval(flightsIntervalId);
      clearInterval(grinIntervalId);
    };
  }, [sortOrder, sorter, credits]);

  return (
    <LoggedInContainer>
      {credits ? (
        <div className="App" style={{ marginLeft: -15, marginRight: -15 }}>
          <Modal opened={opened} onClose={close} title="Messages">
            <ModalElement msg={modal && modal.original_msg} />
            <ModalElement msg={modal && modal.dly_msg} />
            <ModalElement msg={modal && modal.dep_msg} />
            <ModalElement msg={modal && modal.arr_msg} />
            <ModalElement msg={modal && modal.cnc_msg} />
          </Modal>
          <Button
            style={{
              margin: 5,
              padding: 4,
              backgroundColor: "dodgerblue",
              color: "white",
            }}
            onClick={handleSortByDeparture}
          >
            Sort by Departure
          </Button>
          <span style={{ fontSize: 16 }}>GMT: {grin}</span>
          <Button
            style={{
              margin: 5,
              padding: 4,
              backgroundColor: "dodgerblue",
              color: "white",
            }}
            onClick={handleSortByArrival}
          >
            Sort by Arrival
          </Button>
          {flights.map((x) => {
            return (
              <a onClick={() => modalCall(x)}>
                <Card
                  key={x.fid}
                  withBorder
                  style={{
                    backgroundColor: x.cnc_msg
                      ? "#540101cc"
                      : isYymmddBeforeToday(x.dof)
                      ? "#3e8f00"
                      : x.dly_msg
                      ? "#a68800"
                      : getTimeDifference(
                          addTimes(x.arrival_time, x.departure_time),
                          grin
                        ) > 0 && getYYmmDD() == x.dof
                      ? "#3e8f00"
                      : "#282c34",
                  }}
                  shadow="sm"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "white",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {x.cnc_msg ? (
                        <PlaneOff strokeWidth={1} size="35" color="white" />
                      ) : isYymmddBeforeToday(x.dof) ? (
                        <Check strokeWidth={1} size="35" color="white" />
                      ) : getTimeDifference(
                          addTimes(x.arrival_time, x.departure_time),
                          grin
                        ) > 0 && getYYmmDD() == x.dof ? (
                        <Check strokeWidth={1} size="35" color="white" />
                      ) : (
                        getIconByTime(x.arrival_time, x.departure_time, x.dof)
                      )}
                      <div>
                        {x.plane_nr}
                        <span style={{ fontSize: 12 }}> ({x.plane_type})</span>
                      </div>
                      <div style={{ fontSize: 10 }}>PPR: {x.ppr}</div>
                      <div>
                        {x.cnc_msg
                          ? "CANCELLED"
                          : isYymmddBeforeToday(x.dof)
                          ? "LANDED"
                          : getTimeDifference(
                              addTimes(x.arrival_time, x.departure_time),
                              grin
                            ) > 0 && getYYmmDD() == x.dof
                          ? "LANDED"
                          : "PLANNED"}
                      </div>
                    </div>
                    {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 200,
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span
                      style={
                        x.departure_place == "EVRS" ? { fontWeight: 800 } : {}
                      }
                    >
                      {x.departure_place}
                    </span>
                    <br />
                    <span style={{ fontSize: 12 }}>
                      {x.departure_time != x.original_dep_time ? "ETD" : "STD"}{" "}
                      {x.departure_time.slice(0, -3)}
                    </span>
                  </div>
                  <div>
                    <div style={{ fontSize: 11, marginTop: 15 }}>
                      <div style={{ marginBottom: -20 }}>
                        {formatHumanFriendlyDate(x.dof)}
                      </div>
                      <ArrowMoveRight
                        size="60"
                        strokeWidth={0.5}
                        style={{ marginBottom: -20 }}
                      />
                      <div style={{ marginBottom: 20 }}>
                        in flight {x.arrival_time.slice(0, -3)}
                      </div>
                    </div>
                  </div>
                  <div>
                    <span
                      style={
                        x.arrival_place == "EVRS" ? { fontWeight: 800 } : {}
                      }
                    >
                      {x.arrival_place}
                    </span>
                    <br />
                    <span style={{ fontSize: 12 }}>
                      {addTimes(x.departure_time, x.arrival_time).slice(0, -3)}{" "}
                      {addTimes(x.original_dep_time, x.arrival_time) !=
                      addTimes(x.departure_time, x.original_arr_time)
                        ? "ETA"
                        : "STA"}
                    </span>
                  </div>
                </div> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: 350,
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <span
                          style={
                            x.departure_place == "EVRS"
                              ? { fontWeight: 800 }
                              : {}
                          }
                        >
                          {x.departure_place}
                        </span>
                        <br />
                        <div
                          style={{
                            fontSize: 12,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ padding: 5 }}>
                            STD
                            <br />
                            {x.original_dep_time.slice(0, -3)}
                          </div>
                          <div style={{ padding: 5 }}>
                            ETD
                            <br />
                            {x.departure_time.slice(0, -3)}
                          </div>
                          <div style={{ padding: 5 }}>
                            ATD
                            <br />
                            {x.act_dep && x.act_dep.slice(0, -3)}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ fontSize: 11, marginTop: 15 }}>
                          <div style={{ marginBottom: -20 }}>
                            {formatHumanFriendlyDate(x.dof)}
                          </div>
                          <ArrowMoveRight
                            size="60"
                            strokeWidth={0.5}
                            style={{ marginBottom: -20 }}
                          />
                          <div style={{ marginBottom: 20 }}>
                            in flight {x.arrival_time.slice(0, -3)}
                          </div>
                        </div>
                      </div>
                      <div>
                        <span
                          style={
                            x.arrival_place == "EVRS" ? { fontWeight: 800 } : {}
                          }
                        >
                          {x.arrival_place}
                        </span>
                        <br />
                        {/* <span style={{ fontSize: 12 }}>
                      {addTimes(x.original_dep_time, x.arrival_time) !=
                      addTimes(x.departure_time, x.original_arr_time)
                        ? "ETA"
                        : "STA"}{" "}
                      {addTimes(x.departure_time, x.arrival_time).slice(0, -3)}{" "}
                    </span> */}
                        <div
                          style={{
                            fontSize: 12,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div style={{ padding: 5 }}>
                            STA
                            <br />
                            {addTimes(
                              x.original_dep_time,
                              x.original_arr_time
                            ).slice(0, -3)}
                          </div>
                          <div style={{ padding: 5 }}>
                            ETA
                            <br />
                            {addTimes(x.departure_time, x.arrival_time).slice(
                              0,
                              -3
                            )}
                          </div>
                          <div style={{ padding: 5 }}>
                            ATA
                            <br />
                            {x.act_dep &&
                              x.act_arr &&
                              addTimes(x.act_dep, x.act_arr).slice(0, -3)}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div style={{ fontSize: 10, textAlign: "right" }}>
                  Original: <br></br>
                  <span>Dep time: {x.original_dep_time.slice(0, -3)}</span>
                  <br />
                  <span>fly time: {x.original_arr_time.slice(0, -3)}</span>
                </div> */}
                    <div style={{ width: 100 }}></div>
                  </div>

                  <div style={{ color: "white", fontSize: 12 }}>
                    {JSON.parse(x.phone).map((z, index) => (
                      <span key={index}>{z}; </span>
                    ))}
                  </div>
                </Card>
              </a>
            );
          })}
        </div>
      ) : (
        <div style={{ margin: 10 }}>
          <div>
            <Title order={3}>
              If You had at least 3 month flight subscription active, in this
              area you would be able to see the active flight plans for today
              and previous days
            </Title>
          </div>
          <div>
            <Anchor href="/buy-credits" style={{ marginTop: 20 }}>
              <Title order={4}>Buy the subscription here</Title>
            </Anchor>
          </div>
        </div>
      )}
    </LoggedInContainer>
  );
}

export default FlightScreen;
