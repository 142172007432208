import {
  Button,
  Group,
  Container,
  TextInput,
  PasswordInput,
  Text,
  Checkbox,
  Paper,
  Stack,
  Anchor,
  Modal,
  Skeleton,
} from "@mantine/core";
import "react-phone-input-2/lib/style.css";
import classes from "./RegisterHero.module.css";
import { upperFirst, useDisclosure, useToggle } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  checkEmailOrPhone,
  registerUser,
  sentVerifyNumberRequest,
  verifyReceivedSMSCode,
} from "../misc/api";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import StrongPass from "../Components/StrongPass";

export const RegisterAccountScreen = () => {
  const [type, toggle] = useToggle(["register", "register"]);
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      surname: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [rules, setRules] = useState(false);
  const [validate, setValidate] = useState(null);

  const submitRegistrationData = async () => {
    registerUser(form);
    window.location.href = "/login";
  };

  const handleVerifyModal = async () => {
    if (form.values.email && form.values.phone) {
      const doesExists = await checkEmailOrPhone({
        email: form.values.email,
        phone: form.values.phone,
      });

      if (doesExists.rowCount == 0) {
        sentVerifyNumberRequest(form?.values?.phone);
        open();
      } else {
        window.alert("Email or phone already registered!");
      }
    } else {
      window.alert("Please fill phone and email fields!");
    }
  };

  const handleSMSVerify = async () => {
    window.alert(
      "Dev versijā nav iespējams nosūtīt sms! Pabeidzu reģistrāciju bez tā"
    );
    submitRegistrationData();
    // if (validate?.trim().length == 6) {
    //   const response = await verifyReceivedSMSCode(
    //     form?.values?.phone,
    //     validate
    //   );
    //   if (response.status == "approved") {
    //     submitRegistrationData();
    //   } else {
    //     window.alert("Try again!");
    //   }
    // } else {
    //   window.alert("Wrong input length!");
    // }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          top: 0,
          zIndex: -1,
          position: "fixed",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1560837616-fee1f3d8753a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1087&q=60)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <Modal
        opened={rules}
        onClose={() => setRules(false)}
        title="Terms & Conditions"
        centered
      >
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />

        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </Modal>
      <Modal opened={opened} onClose={close} title="Validate number" centered>
        <TextInput
          required
          label="Verification code sent to your number:"
          placeholder="XXXXXX"
          value={validate}
          onChange={(event) => setValidate(event.target.value)}
          error={form.errors.email && "Invalid email"}
          radius="md"
        />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button style={{ marginTop: 20 }} onClick={handleSMSVerify}>
            Confirm
          </Button>
        </div>
      </Modal>
      <Container
        size="sm"
        className={classes.wrapper}
        style={{ marginTop: 100 }}
      >
        <Paper radius="md" p="xl" withBorder>
          <Text size="lg" fw={500}>
            Welcome to Spilve
          </Text>

          <Group grow mb="md" mt="md"></Group>

          <form
            onSubmit={form.onSubmit(() => {
              //submitRegistrationData();
              handleVerifyModal();
            })}
          >
            <Stack>
              {type === "register" && (
                <>
                  <TextInput
                    label="Name"
                    placeholder="Your name"
                    value={form.values.name}
                    onChange={(event) =>
                      form.setFieldValue("name", event.currentTarget.value)
                    }
                    radius="md"
                  />
                  <TextInput
                    label="Surname"
                    placeholder="Your surname"
                    value={form.values.surname}
                    onChange={(event) =>
                      form.setFieldValue("surname", event.currentTarget.value)
                    }
                    radius="md"
                  />
                </>
              )}

              <TextInput
                required
                label="Email"
                placeholder="hello@spilve.org"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email && "Invalid email"}
                radius="md"
              />
              {type === "register" && (
                <>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      color: "#212529",
                      marginBottom: -10,
                    }}
                  >
                    Phone number<span style={{ color: "red" }}> *</span>
                  </span>
                  <PhoneInput
                    country={"lv"}
                    value={form?.values?.phone}
                    onChange={(p) => form.setFieldValue("phone", p)}
                  />
                </>
              )}
              <StrongPass
                inputLabel="Password"
                placeholder="Your password"
                value={form?.values?.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Password should include at least 6 characters"
                }
              />
              {/* <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Password should include at least 6 characters"
                }
                radius="md"
              /> */}

              {type === "register" && (
                <>
                  <PasswordInput
                    required
                    label="Repeat password"
                    placeholder="Repeat password"
                    value={form.values.passwordRepeat}
                    onChange={(event) =>
                      form.setFieldValue(
                        "passwordRepeat",
                        event.currentTarget.value
                      )
                    }
                    error={
                      form.values.passwordRepeat &&
                      form.values.passwordRepeat != form.values.password &&
                      "Password should match!"
                    }
                    radius="md"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      label={<span>I accept </span>}
                      checked={form.values.terms}
                      onChange={(event) =>
                        form.setFieldValue("terms", event.currentTarget.checked)
                      }
                    />
                    <Anchor
                      style={{ marginLeft: 5 }}
                      // onClick={() => (window.location.href = "/rules")}
                      onClick={() => setRules(true)}
                    >
                      {" "}
                      terms and conditions
                    </Anchor>
                  </div>
                </>
              )}
            </Stack>
            <Group justify="space-between" mt="xl">
              <Anchor
                component="button"
                type="button"
                c="dimmed"
                onClick={() => (window.location.href = "/login")}
                size="xs"
              >
                Already have an account? Login
              </Anchor>
              <Button type="submit" radius="xl">
                {upperFirst(type)}
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </>
  );
};
