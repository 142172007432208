import React, { useEffect, useState } from "react";
import { LoggedInContainer } from "../../Components/LoggedInContainer";
import { Checkbox, TextInput, Button } from "@mantine/core";
import { getQuestions, saveQuestions } from "../../misc/api";

export const AdminQuestions = () => {
  const [questions, setQuestions] = useState();
  const [allExpanded, setAllExpanded] = useState(false);
  const [expandedRules, setExpandedRules] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getQuestions().then((x) => {
      // Map over questions and transform options string into an object
      const transformedQuestions = x?.questions.map((question) => {
        const options = JSON.parse(question.options);
        return { ...question, questionText: question.question_text, options };
      });
      console.log(transformedQuestions);
      setQuestions(transformedQuestions);

      // Initialize expanded state
      const initialExpandedState = {};
      transformedQuestions.forEach((question, index) => {
        initialExpandedState[index] = false;
      });
      setExpandedRules(initialExpandedState);
      setLoading(false);
    });
  }, []);

  const saveQuestionFn = () => {
    saveQuestions(questions);
    window.location.reload();
  };

  const handleQuestionChange = (index, newValue) => {
    const newQuestions = [...questions];
    newQuestions[index].questionText = newValue;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, newValue) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = {
      title: newValue,
      right: newQuestions[questionIndex].options[optionIndex].right,
    };
    setQuestions(newQuestions);
  };

  const markQuestionAsRight = (questionIndex, optionIndex, newValue) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = {
      title: newQuestions[questionIndex].options[optionIndex].title,
      right: newValue,
    };
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { questionText: "", options: [""] }]);
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push({ title: "", right: false });
    setQuestions(newQuestions);
  };

  // const removeQuestion = (questionIndex) => {
  //   const newQuestions = [...questions];
  //   newQuestions.splice(questionIndex, 1);
  //   setQuestions(newQuestions);
  // };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  const toggleAllExpansion = () => {
    setAllExpanded((prev) => !prev);
    const newExpandedState = {};
    questions.forEach((_, index) => {
      newExpandedState[index] = !allExpanded;
    });
    setExpandedRules(newExpandedState);
  };

  const toggleQuestionExpansion = (index) => {
    setExpandedRules((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const removeQuestion = (index) => {
    const newQuestions = [...questions];
    const questionToRemove = newQuestions[index];

    if (questionToRemove.id) {
      newQuestions[index] = { ...questionToRemove, deleted: true };
    } else {
      newQuestions.splice(index, 1);
    }
    setQuestions(newQuestions);
  };

  return (
    <LoggedInContainer>
      <div
        style={{
          display: "flex",
          zIndex: 20,
          backgroundColor: "white",
          justifyContent: "space-between",
          position: "fixed",
          padding: 10,
          width: "-webkit-fill-available",
          marginLeft: -20,
          marginTop: -20,
        }}
      >
        <h2>Questions (ENG)</h2>
        <Button
          onClick={() => toggleAllExpansion()}
          style={{ marginTop: 10, marginLeft: 10 }}
        >
          {allExpanded ? "Hide All" : "Expand All"}
        </Button>
      </div>
      <div style={{ paddingTop: 80 }}>
        {questions?.map(
          (question, questionIndex) =>
            question.deleted != true && (
              <div
                key={questionIndex}
                style={{
                  padding: 15,
                  backgroundColor: "#f1f1f1",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginBottom: 10,
                    textDecoration: "underline",
                  }}
                  onClick={() => toggleQuestionExpansion(questionIndex)}
                >
                  {expandedRules[questionIndex] ? "Hide" : "Show"} Question:{" "}
                  {question.questionText}
                </div>
                {expandedRules[questionIndex] && (
                  <>
                    {/* <Button
                  onClick={() => removeQuestion(questionIndex)}
                  style={{
                    top: 0,
                    right: 5,
                    float: "right",
                    backgroundColor: "red",
                    color: "white",
                  }}
                  compact
                >
                  X
                </Button> */}
                    <div></div>
                    <TextInput
                      label="Jautājums"
                      value={question.questionText}
                      onChange={(event) =>
                        handleQuestionChange(
                          questionIndex,
                          event.currentTarget.value
                        )
                      }
                    />
                    <div style={{ display: "flex", paddingTop: 10 }}>
                      <small style={{ fontSize: 12 }}>Pareizais?</small>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 10,
                      }}
                    >
                      {question.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="options"
                          style={{
                            display: "flex",
                            marginTop: 5,
                            marginBottom: 5,
                            marginLeft: 10,
                          }}
                        >
                          <Checkbox
                            checked={option.right}
                            size="xl"
                            onChange={(event) =>
                              markQuestionAsRight(
                                questionIndex,
                                optionIndex,
                                option.right ? false : true
                              )
                            }
                          />
                          <TextInput
                            placeholder="Atbilde"
                            value={option.title}
                            style={{ width: "100%", marginLeft: 10 }}
                            onChange={(event) =>
                              handleOptionChange(
                                questionIndex,
                                optionIndex,
                                event.currentTarget.value
                              )
                            }
                          />
                          <Button
                            onClick={() =>
                              removeOption(questionIndex, optionIndex)
                            }
                            style={{
                              marginLeft: 10,
                              marginTop: 5,
                              color: "white",
                              backgroundColor: "red",
                            }}
                            compact
                          >
                            X
                          </Button>
                        </div>
                      ))}
                      <Button
                        onClick={() => addOption(questionIndex)}
                        style={{ marginLeft: 10, width: 50, fontSize: 20 }}
                      >
                        +
                      </Button>
                      <Button
                        onClick={() => removeQuestion(questionIndex)}
                        style={{
                          marginTop: 10,
                          backgroundColor: "red",
                          color: "white",
                        }}
                        variant="link"
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
        )}
      </div>
      <Button onClick={addQuestion} style={{ marginTop: 10 }}>
        Pievienot jautājumu
      </Button>
      <hr></hr>
      <div>
        <Button
          onClick={() => saveQuestionFn()}
          disabled={loading}
          style={{ marginTop: 10, paddingLeft: 50, paddingRight: 50 }}
        >
          Save
        </Button>
      </div>
    </LoggedInContainer>
  );
};
