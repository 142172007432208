import { Center, Text, TextInput, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

export function TooltipIcon({
  label,
  placeholder,
  tooltip,
  value,
  onChange,
  required,
}) {
  const rightSection = (
    <Tooltip
      label={tooltip}
      position="top-end"
      withArrow
      transitionProps={{ transition: "pop-bottom-right" }}
    >
      <Text color="dimmed" sx={{ cursor: "help" }}>
        <Center>
          <IconInfoCircle size="1.1rem" stroke={1.5} />
        </Center>
      </Text>
    </Tooltip>
  );

  return (
    <TextInput
      required={required ? true : false}
      rightSection={tooltip && rightSection}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}
