import { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import { UnstyledButton, Group, Avatar } from "@mantine/core";
import {
  IconCurrency,
  IconFile,
  IconLogout,
  IconPlane,
  IconPlaneArrival,
  IconPlaneDeparture,
  IconPlaneInflight,
  IconReportMoney,
  IconUser,
  IconUserCircle,
} from "@tabler/icons-react";
import { getGroup } from "../misc/tokens";
import { getRulesRead } from "../misc/api";

export function LoggedInContainer({ children, setTr, tr }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [readTerms, setTerms] = useState(false);

  const admin = getGroup() == 0 ? true : false;

  useEffect(() => {
    getRulesRead().then((x) => {
      setTerms(x);
      setTr && setTr(!x);
    });
  }, []);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 250, lg: 350 }}
        >
          {admin == true && (
            <UnstyledButton
              style={{
                width: 220,
                padding: 10,
                borderBottom: "0.1px solid lightgrey",
              }}
              onClick={() => (window.location.href = "/admin")}
            >
              <Group>
                <IconUserCircle />
                <div>
                  <Text>Admin</Text>
                </div>
              </Group>
            </UnstyledButton>
          )}
          {admin == true && (
            <UnstyledButton
              style={{
                width: 220,
                padding: 10,
                borderBottom: "0.1px solid lightgrey",
              }}
              onClick={() => (window.location.href = "/flight-data")}
            >
              <Group>
                <IconPlaneInflight />
                <div>
                  <Text>Airfield info</Text>
                </div>
              </Group>
            </UnstyledButton>
          )}
          <UnstyledButton
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
            }}
            onClick={() => (window.location.href = "/rules")}
          >
            <Group>
              <IconFile />
              <div>
                <Text>Spilve flight info</Text>
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            disabled={!readTerms}
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
              cursor: readTerms ? "pointer" : "default",
              color: readTerms ? "black" : "gray",
            }}
            onClick={() => (window.location.href = "/buy-credits")}
          >
            <Group>
              <IconCurrency />
              <div>
                <Text>Buy Credits</Text>
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            disabled={!readTerms}
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
              cursor: readTerms ? "pointer" : "default",
              color: readTerms ? "black" : "gray",
            }}
            onClick={() => (window.location.href = "/planes")}
          >
            <Group>
              <IconPlane />
              <div>
                <Text>My Aircraft info</Text>
                {/* <small style={{ color: "red" }}>Coming soon...</small> */}
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            disabled={!readTerms}
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
              cursor: readTerms ? "pointer" : "default",
              color: readTerms ? "black" : "gray",
            }}
            onClick={() => (window.location.href = "/request")}
          >
            <Group>
              <IconPlaneDeparture />
              <div>
                <Text>Request flight</Text>
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            disabled={!readTerms}
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
              cursor: readTerms ? "pointer" : "default",
              color: readTerms ? "black" : "gray",
            }}
            onClick={() => (window.location.href = "/flight-history")}
          >
            <Group>
              <IconPlaneArrival />
              <div>
                <Text>Flight history</Text>
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            disabled={!readTerms}
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
              cursor: readTerms ? "pointer" : "default",
              color: readTerms ? "black" : "gray",
            }}
            onClick={() => (window.location.href = "/payment-history")}
          >
            <Group>
              <IconReportMoney />
              <div>
                <Text>Payment history</Text>
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            style={{
              width: 220,
              padding: 10,
              borderBottom: "0.1px solid lightgrey",
            }}
            onClick={() => (window.location.href = "/profile")}
          >
            <Group>
              <IconUser />
              <div>
                <Text>My profile</Text>
              </div>
            </Group>
          </UnstyledButton>
          <UnstyledButton
            style={{ width: 200, padding: 10, position: "fixed", bottom: 0 }}
            onClick={() => (window.location.href = "/logout")}
          >
            <Group>
              <Avatar size={40} color="blue">
                <IconLogout />
              </Avatar>
              <div>
                <Text>Logout</Text>
              </div>
            </Group>
          </UnstyledButton>
        </Navbar>
      }
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <a href="/main" style={{ textDecoration: "none", color: "black" }}>
              <h2>Spilve airport</h2>
            </a>
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}
