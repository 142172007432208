import { useEffect, useState } from "react";
import {
  Container,
  Title,
  Accordion,
  Text,
  Checkbox,
  Anchor,
} from "@mantine/core";
import "react-phone-input-2/lib/style.css";
import classes from "../Screens/RegisterHero.module.css";
import { getRules, setRulesRead } from "../misc/api";

export const AirplaneRules = ({
  setAgreeChecked,
  agreeChecked,
  showRules = true,
  style,
  showCheckmark,
}) => {
  const [rules, setRules] = useState();
  const [cx, setCx] = useState(false);

  useEffect(() => {
    getRules().then((x) => {
      setRules(x.rules);
    });
  }, []);

  const userReadRulesAndAgreed = () => {
    if (window.confirm("Did you read the rules and agree to follow them?")) {
      setCx(true);
      setRulesRead();
      window.location.href= '/main';
    }
  };

  return (
    <Container size="sm" className={classes.wrapper} style={style}>
      <div>
        <Title ta="center" className={classes.title}>
          Spilve airport Rules
        </Title>
        <Text
          c="dimmed"
          mt="md"
          style={{ textAlign: "center", marginBottom: 20 }}
        >
          Please read carefully and get acquinted with the rules.
        </Text>
      </div>
      <Title ta="center" style={{ fontSize: 14, marginBottom: 20 }}>
        To read full Spilve information and Rules please follow{" "}
        <Anchor href="https://ais.lgs.lv/aiseaip" target="_blank">
          this link to Latvia AIP online version
        </Anchor>
      </Title>
      <Accordion variant="separated">
        {rules &&
          rules?.map((rule) => (
            <Accordion.Item className={classes.item} value={rule.id.toString()}>
              <Accordion.Control>{rule.title}</Accordion.Control>
              <Accordion.Panel>
                <div dangerouslySetInnerHTML={{ __html: rule.content }}></div>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
      </Accordion>
      {showRules && (
        <strong>
          <Checkbox
            style={{ marginTop: 20 }}
            label={"I read the rules and agree to them"}
            checked={agreeChecked}
            onChange={(event) => setAgreeChecked(event.currentTarget.checked)}
          />
        </strong>
      )}
      {showCheckmark && (
        <strong>
          <Checkbox
            style={{ marginTop: 20 }}
            label={"I read the rules and agree to them"}
            checked={cx}
            onChange={(event) => userReadRulesAndAgreed()}
          />
        </strong>
      )}
    </Container>
  );
};
