import { Badge, Button, Modal, Select, Table, TextInput } from "@mantine/core";
import { LoggedInContainer } from "../../Components/LoggedInContainer";
import { useCallback, useEffect, useState } from "react";
import { adminAddPayment, adminGetClientPayments } from "../../misc/api";
import { TooltipIcon } from "../../Components/TooltipIcon";
import { DateInput, TimeInput } from "@mantine/dates";

export const AdminPayments = () => {
  const [payments, setPayments] = useState(null);
  const [modal, setModal] = useState(null);
  const [visible, setVisible] = useState(false);
  // const { cost, product, user_nr } = req.body;

  useEffect(() => {
    adminGetClientPayments().then((x) => setPayments(x));
  }, [visible, modal]);

  const submitNewPayment = () => {
    adminAddPayment(modal);
    setVisible(false);
    setModal(null);
  };

  const rows = payments?.map((element) => (
    <tr
      style={{ cursor: "pointer" }}
      key={element.transaction_id}
      onClick={() => {
        setModal(element);
        setVisible(true);
      }}
    >
      <td style={{ fontSize: 12 }}>
        {element.phone ? element.phone : element.user_nr}
        <br></br>
        {element.email ? element.email : element.user_name}
      </td>
      <td style={{ fontSize: 12 }}>
        {element.order_reference}
        {element.product.includes("custom-") ? (
          <small>
            <br />
            Admin pievienots maksājums
          </small>
        ) : (
          <small>
            <br />
            Online maksājums
          </small>
        )}
      </td>
      <td>{new Date(element.paid_confirmed).toLocaleString()}</td>
      <td>{element.amount}.00 €</td>
      <td>
        {
          <Badge
            color={
              element.status == "pending"
                ? "orange"
                : element.status == "paid"
                ? "green"
                : "red"
            }
          >
            {element.status}
          </Badge>
        }
      </td>
      <td>
        {element?.valid ? (
          <span>
            Sub till <br /> {new Date(element?.valid).toLocaleDateString()}
          </span>
        ) : element?.amount == 25 ? (
          "One flight"
        ) : (
          "Two flights"
        )}
      </td>
    </tr>
  ));

  const handleInputChange = useCallback(
    (fieldName, newValue) => {
      setModal((prevState) => ({
        ...prevState,
        [fieldName]: newValue,
      }));
    },
    [modal]
  );

  return (
    <LoggedInContainer>
      <Modal
        opened={visible}
        onClose={() => {
          setVisible(false);
          setModal(null);
        }}
        title={
          modal?.transaction_id
            ? "Labot maksājumu"
            : "Pievienot maksājumu / Lidojumu"
        }
      >
        {!modal?.transaction_id ? (
          <>
            <TextInput
              onChange={(x) => setModal({ ...modal, user_nr: x.target.value })}
              value={modal?.user_nr}
              label="Klienta tel nr."
            />
            <TextInput
              onChange={(x) => setModal({ ...modal, name: x.target.value })}
              value={modal?.name}
              label="Klienta Vārds"
            />
            <TextInput
              onChange={(x) => setModal({ ...modal, cost: x.target.value })}
              value={modal?.cost}
              label="Summa"
            />
            <Select
              label="Maksājuma mērķis"
              placeholder="Izvēlies vienu"
              value={modal?.product}
              onChange={(x) => setModal({ ...modal, product: x })}
              data={[
                { value: "ot", label: "Viens lidojums" },
                { value: "tt", label: "Divi lidojumi" },
                { value: "30 days", label: "Mēneša abonaments" },
                { value: "90 days", label: "3 Mēnešu abonaments" },
                { value: "180 days", label: "6 Mēnešu abonaments" },
                { value: "365 days", label: "Gada abonaments" },
              ]}
            />
            <TooltipIcon
              value={modal?.departure_place}
              onChange={(event) =>
                handleInputChange("departure_place", event.target.value)
              }
              label="Origin airport"
              placeholder="RIX"
            />
            <TooltipIcon
              value={modal?.arrival_place}
              onChange={(event) =>
                handleInputChange("arrival_place", event.target.value)
              }
              label="Arrival airport"
              placeholder="RIX"
            />
            <DateInput
              value={modal?.dof}
              onChange={(v) => handleInputChange("dof", v)}
              label="DOF"
              placeholder="DDMMYY"
              valueFormat="DDMMYY"
            />
            <TimeInput
              value={modal?.departure_time}
              onChange={(event) =>
                handleInputChange("departure_time", event.target.value)
              }
              label="Planned time of departure"
            />
            <TimeInput
              value={modal?.flight_lenght}
              onChange={(event) =>
                handleInputChange("flight_lenght", event.target.value)
              }
              label="Lenght of flight"
            />
          </>
        ) : (
          <div style={{ marginTop: 100 }}>
            <Select
              label="Maksājuma status"
              placeholder="Izvēlies vienu"
              value={modal?.status}
              onChange={(x) => setModal({ ...modal, status: x })}
              data={[
                { value: "paid", label: "Apmaksāts" },
                { value: "cancelled", label: "Atcelts" },
                { value: "pending", label: "Gaida apmaksu" },
              ]}
            />
          </div>
        )}
        <div style={{ marginTop: 20 }}>
          <Button onClick={() => submitNewPayment()}>Apstiprināt</Button>
        </div>
      </Modal>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Manage payments</h2>
        <Button onClick={() => setVisible(true)}>Add Payment / Flight</Button>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Client</th>
            <th>Payment ref.</th>
            <th>Datums</th>
            <th>Summa</th>
            <th>Status</th>
            <th>Kredīti</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </LoggedInContainer>
  );
};
